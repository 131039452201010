var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{attrs:{"align-h":"center"}},[(!_vm.QRCodeValid)?_c('b-col',{attrs:{"lg":"6"}},[_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Error ")]),_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("QR Code is invalid.")])])])],1):_vm._e(),(_vm.QRCodeValid)?_c('b-col',{attrs:{"lg":"6"}},[_c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticClass:"ml-1 font-weight-bold"},[_c('feather-icon',{attrs:{"icon":"UserIcon"}}),_vm._v(" Attendees ")],1)]},proxy:true}],null,false,1783502743)},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.confirmAttendance()}}},[_c('b-form-checkbox-group',{model:{value:(_vm.requestBody.attendee_IDs),callback:function ($$v) {_vm.$set(_vm.requestBody, "attendee_IDs", $$v)},expression:"requestBody.attendee_IDs"}},[_c('b-card',[_c('b-table',{staticClass:"attendee-table",attrs:{"head-variant":"light","items":_vm.bookingDetails.attendees,"fields":_vm.columns,"responsive":""},scopedSlots:_vm._u([{key:"cell(check)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{staticClass:"mb-1",attrs:{"value":item.id}})]}},{key:"cell(user.profile.birthday)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user && item.user.profile.birthday ? ((_vm.$moment().diff(item.user.profile.birthday, 'years')) + " Years") : '')+" ")]}},{key:"cell(user.profile.vaccinated)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user ? (item.user.profile.vaccinated ? 'Yes' : 'No') : '')+" ")]}},{key:"cell(user.profile.gender)",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.user.profile.gender)+" ")]):_vm._e()]}}],null,false,1976955374)})],1)],1),_c('submit-button',{attrs:{"handle-submit":_vm.handleSubmit,"submit":_vm.confirmAttendance}}),_c('back')],1)],1)],1):_vm._e(),(_vm.QRCodeValid)?_c('b-col',{attrs:{"lg":"6"}},[_c('session-details',{attrs:{"session":_vm.bookingDetails.session}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }