<template>
  <b-row
    align-h="center"
  >
    <!-- invalid QR Code alert -->
    <b-col
      v-if="!QRCodeValid"
      lg="6"
    >
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Error
        </h4>
        <div class="alert-body">
          <span>QR Code is invalid.</span>
        </div>
      </b-alert>
    </b-col>

    <b-col
      v-if="QRCodeValid"
      lg="6"
    >
      <b-card>
        <template
          #header
        >
          <span class="ml-1 font-weight-bold">
            <feather-icon
              icon="UserIcon"
            />
            Attendees
          </span>
        </template>

        <b-form @submit.prevent="confirmAttendance()">

          <b-form-checkbox-group
            v-model="requestBody.attendee_IDs"
          >
            <b-card>
              <b-table
                head-variant="light"
                :items="bookingDetails.attendees"
                :fields="columns"
                class="attendee-table"
                responsive
              >
                <template #cell(check)="{item}">
                  <b-form-checkbox
                    :value="item.id"
                    class="mb-1"
                  />
                </template>
                <template #cell(user.profile.birthday)="{item}">
                  {{ item.user && item.user.profile.birthday ?
                    `${$moment().diff(item.user.profile.birthday, 'years')} Years`
                    : '' }}
                </template>
                <template #cell(user.profile.vaccinated)="{item}">
                  {{ item.user ? (item.user.profile.vaccinated ? 'Yes' : 'No') : '' }}
                </template>
                <template #cell(user.profile.gender)="{item}">
                  <span
                    v-if="item.user"
                    class="text-capitalize"
                  >
                    {{ item.user.profile.gender }}
                  </span>
                </template>
              </b-table>
            </b-card>
          </b-form-checkbox-group>

          <submit-button
            :handle-submit="handleSubmit"
            :submit="confirmAttendance"
          />

          <back />
        </b-form>
      </b-card>
    </b-col>

    <b-col
      v-if="QRCodeValid"
      lg="6"
    >
      <session-details :session="bookingDetails.session" />
    </b-col>
  </b-row>
</template>
<script>
import handleFormData from '@/common/compositions/common/handleFormData'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import SessionDetails from '@/common/components/EventAttendance/SessionDetails.vue'
import Back from '@/common/components/common/FormInputs/Back.vue'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'

export default {
  name: 'ConfirmAttendance',
  components: { SessionDetails, Back, SubmitButton },
  data() {
    return {
      bookingDetails: {},
      columns: [
        { key: 'check' },
        { key: 'attendee_name' },
        { key: 'user.profile.vaccinated', label: 'vaccinated' },
        { key: 'user.profile.birthday', label: 'age' },
        { key: 'user.profile.gender', label: 'gender' },
      ],
      requestBody: { QR_code: this.$route.params.QRCode, attendee_IDs: [] },
      QRCodeValid: true,
    }
  },
  setup() {
    const { setFormData, formData } = handleFormData()

    const { successfulOperationAlert, failedOperationAlert } = handleAlerts()

    return {
      setFormData,
      formData,
      successfulOperationAlert,
      failedOperationAlert,
    }
  },
  created() {
    this.getBookingDetailsByQR()
  },
  methods: {
    checkAttendedUsers() {
      this.bookingDetails.attendees.forEach(attendee => {
        if (attendee.attending_status === 'attended') {
          this.requestBody.attendee_IDs.push(attendee.id)
        }
      })
    },
    getBookingDetailsByQR() {
      this.setFormData(this.requestBody)
      this.$activities
        .post('/internalops/get-booking-details', this.formData).then(res => {
          this.bookingDetails = res.data.data
          this.checkAttendedUsers()
        }).catch(() => {
          this.QRCodeValid = false
        })
    },
    confirmAttendance() {
      this.setFormData(this.requestBody)
      return this.$activities
        .post('/internalops/verify-booking-attendance', this.formData).then(() => {
          this.successfulOperationAlert('Attendance Verified Successfully')
          this.$router.push({ name: 'scan-qr' })
        })
    },
  },
}
</script>
<style lang="scss">
.attendee-table table thead th {
    background-color: transparent !important;
    border:0 !important;
}
</style>
